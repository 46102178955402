import React from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col,
    Input, Row,
    Table,
} from "reactstrap";
import "./ComponentsCustomStyle.css"

import {withTranslation} from "react-i18next";

import {Scan} from 'models';

class Dimensions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false
        };
    }

    componentDidMount() {
        this.updateScan();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.uuid !== prevProps.uuid) {
            this.updateScan();
        }
    }

    updateScan() {
        if (this.props.uuid) {
            Scan.where('id', this.props.uuid).with('measurements').first()
                .then((response) => {
                    let measurements = response.getData().getMeasurements();

                    this.setState({
                        isLoaded: true,
                        width: Math.round(measurements.getAttribute('width')),
                        height: Math.round(measurements.getAttribute('height')),
                        length: Math.round(measurements.getAttribute('length')),
                        weight: Math.round(measurements.getAttribute('weight_calc')),
                        disabled: true,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        }
    }

    edit() {
        this.setState({
            disabled: false
        })

    }

    save() {
        this.setState({
            disabled: true
        })
    }

    cancel() {
        this.setState({
            width: this.state.width,
            height: this.state.height,
            length: this.state.length,
            disabled: true
        })
    }


    renderButton() {
        if (this.state.disabled) {
            return (
                <Button className="float-right" onClick={() => {
                    console.log("You've clicked LIKE button on Edit");
                    this.edit();
                }} color="primary">
                    <i className="fa fa-edit"/> {this.props.t('Edit')} </Button>
            )
        } else return (<div>
                <Button style={{marginLeft: '10px'}} className="float-right" onClick={() => {
                    console.log("You are canceling changes");
                    this.cancel()
                }} color="danger">
                    <i className="fa fa-times"/>{this.props.t('Cancel')}</Button>
                <Button className="float-right" onClick={() => {
                    console.log("You've clicked LIKE button on Save");
                    this.save();
                }} color="success">
                    <i className="fa fa-check"/> {this.props.t('Save')} </Button>
            </div>
        )
    }

    render() {
        const {error, isLoaded} = this.state;
        if (error) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t('Error')}: {error.message}</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else if (!isLoaded) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t('Loading')} {this.props.t('Measurements')} ...</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (
                <div>
                    <Table borderless>
                        <tbody>
                        <tr>
                            <td style={{verticalAlign: "middle"}}>
                                <p>{this.props.t('Width')}</p>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <Input className="w-50" style={{display: 'inline-block'}}
                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                       value={this.state.width}
                                       onChange={(e) => this.setState({width: e.target.value})}/>
                                <span style={{marginLeft: '10px'}}>mm</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign: "middle"}}>
                                <p>{this.props.t('Height')}</p>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <Input className="w-50" style={{display: 'inline-block'}}
                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                       value={this.state.height}
                                       onChange={(e) => this.setState({height: e.target.value})}/>
                                <span style={{marginLeft: '10px'}}>mm</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign: "middle"}}>
                                <p>{this.props.t('Length')}</p>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <Input className="w-50" style={{display: 'inline-block'}}
                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                       value={this.state.length}
                                       onChange={(e) => this.setState({length: e.target.value})}/>
                                <span style={{marginLeft: '10px'}}>mm</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign: "middle"}}>
                                <p>{this.props.t('Weight')}</p>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <Input className="w-50" style={{display: 'inline-block'}}
                                       disabled={(this.state.disabled) ? "disabled" : ""}
                                       value={this.state.weight}
                                       onChange={(e) => this.setState({weight: e.target.value})}/>
                                <span style={{marginLeft: '10px'}}>g</span>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    {this.renderButton()}
                </div>
            )
        }
    }

}

export default withTranslation()(Dimensions);