import React, {Component} from 'react';
import apiClient, {baseURL} from "../../services/apiService"; // import the component above linking to file we just created.

import {Scan} from "../../models";

// keep this import!
// it is needed for parsing the models (glb)
// a missing import will lead to parse errors at runtime
// but the compiler won't emit an error / a warning
import "@babylonjs/loaders/glTF";

import {Engine, Scene} from 'react-babylonjs'
import { Vector3, Color3} from '@babylonjs/core'

import {SortDirection} from "coloquent";

// TODO: use AssetManager for switching between different models (scans)
import ScaledModelWithProgress from "./ScaledModelWithProgress";

export default class Viewer extends Component {

    state = {
        user: null,
        error: null,
        loading: true,
        box: null,
        isLoading: true,
        scanData: []
    };

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            scanData: [],
            data: null,
            inertia: 0.5,

        };
        // following is not required if you are using => functions in ES6.
        // this.onData1Changed = this.onData1Changed.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.uuid !== prevProps.uuid) {
            this.updateScan();
        }
    }

    componentDidMount() {
        this.updateScan();
    }

    updateScan() {
        if (this.props.uuid) {
            const that = this;

            Scan.where('id', this.props.uuid)
                .first()
                .then((scanResponse) => {
                    return scanResponse.getData().revisions()
                        .orderBy('position', SortDirection.DESC)
                        .limit(1)
                        .first()
                        .then((revResponse) => {

                            that.setState({tags: revResponse.getData().getAttribute('tags')});

                            console.log("Tags are:");
                            console.log(revResponse.getData().getAttribute('tags'));

                            return revResponse.getData().media()
                                /* TODO: no condition available for including files with one of the tags (OR) */
                                //.where('tag', 'K4R_3DData')
                                //.where('tag', 'Kap_3DData')
                                .where('filename', 'scan-final')
                                .where('extension', 'glb')
                                // TODO: choose a model to display - until then first search result will be rendered
                                .first()
                                .then((modelResponse) => {
                                    // retrieve model via axios because of authorization issues in react-babylonjs
                                    apiClient.get(this.getURLForFile(modelResponse.data), {responseType: 'arraybuffer'})
                                        .then((response) => {
                                            // TODO: remove debug statement
                                            console.log(response.data);

                                            if (response.data) {
                                                let base64 = Buffer.from(response.data, 'binary')
                                                    .toString('base64');

                                                that.setState({dataURL: "data:base64," + base64});
                                            }
                                        })
                                })
                        })
                }).catch((error) => {
                console.log("FAILED", error);
            });
        }
    }

    getURLForFile(file) {
        return `${baseURL}cache/` +
            file.getAttribute('directory') +
            '/' +
            file.getAttribute('filename') +
            '.' +
            file.getAttribute('extension');
    }

    onSceneMount = (e) => {
        let {canvas, scene, engine, box, v2} = e;

        /* don't propagate mouse movements to the parent components
            in order to avoid website scrolling while zooming in and out */
        canvas.addEventListener("wheel", evt => {
            evt.stopPropagation();
            evt.preventDefault()
        });
    }

    render() {
        // removed ply based scene creation
        // from now on using glb as source for all models

        // render model when loaded
        if (this.state.dataURL) {
            return (
                <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                    <Engine antialias adaptToDeviceRatio canvasId='babylonJS'>
                        <Scene onSceneMount={this.onSceneMount} disposeInstanceOnUnmount={true}>
                            <arcRotateCamera name='camera1' alpha={Math.PI / 2} beta={Math.PI / 2} radius={9.0}
                                             target={Vector3.Zero()} minZ={0.001} />
                            <hemisphericLight name='light1' intensity={1} direction={Vector3.Zero()} />

                            <ScaledModelWithProgress rootUrl={''} sceneFilename={this.state.dataURL} scaleTo={5}
                                                     center={new Vector3(0, 0, 0)} progressBarColor={Color3.FromInts(255, 165, 0)}
                                                     onModelLoaded={this.onModelLoaded}
                            />
                        </Scene>
                    </Engine>
                    {this.state.tags && this.state.tags.license && <br/>}
                    {this.state.tags && this.state.tags.license && <p>{this.state.tags.license}</p>}
                </div>
            )
        } else {
            return null;
        }

    }
}
