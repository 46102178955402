import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// reactstrap components
import {
    // Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Input,
    Label
} from "reactstrap";
// import { Route, /* Redirect, */ Router, /* Link */ } from "react-router-dom";
// import { createBrowserHistory } from 'history';

import { withRouter } from "react-router";
import {withTranslation} from "react-i18next";
import './ComponentsCustomStyle.css'

import moment from 'moment'

// import ArticleDetails from "./articleDetails";

import {baseURL} from '../services/apiService'

import {Product} from "../models";
import {SortDirection} from "coloquent";

import { Route, Redirect, Router, Link } from "react-router-dom";
import { createBrowserHistory } from 'history';
import ArticleDetails from "./articleDetails";

const  history=createBrowserHistory();
const handleClick = () => this.props.history.push('/');
const RedirectToArticleDetails =
    <Router history={history}>
        <Route path="/articleDetails" component={ArticleDetails}  />
    </Router>;


// an article (or product) to show in the results
class SearchResult {

    // attributes contain json the response data
    scan;
    revision;
    product;
    measurements;
    image;
    user;

    constructor(scan, revision, product, measurements, image, user) {
        this.scan = scan;
        this.revision = revision;
        this.product = product;
        this.measurements = measurements;
        this.image = image;
    }

}

// uncomment for testing loading message
/* function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
} */

class DataTable extends React.Component {
    constructor(props) {
        super(props);

        console.log("Props are: ")
        console.log(props)

        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        // fetch products with recent scans
        Product.orderBy('updatedAt', SortDirection.DESC)
            //.option('fields[media]', 'filename,extension,directory')
			.option('filter[withoutTestData]')
            .option('fields[measurements]', 'length,width,height,weight_calc')
            .option('fields[revisions]','position')
            .option('fields[scans]','finished,revisions,measurements')
            .option('fields[products]', 'scans,skus')
            .with('scans.measurements,scans.revisions')
            .get()
            .then((productResponse) => {
                console.log(productResponse);

                let promises = [];

                for (let i = 0; i < productResponse.data.length; i++) {
                    let product = productResponse.getData()[i];

                    let latestScan = product.getScans()
                        .sort(
                            (a, b) => new Date(b.getAttribute('updatedAt') - new Date(a.getAttribute('updatedAt')).getTime()).getTime()
                        )[0];

                    let latestScanRevision = latestScan.getRevisions()
                        .sort((a, b) => b.getAttribute('position') - a.getAttribute('position'))[0];

                    promises[i] = latestScanRevision.media()
                        .where('tag', 'Kap_360Images')
                        .orderBy('filename', 'asc')
                        .option('fields[media]', 'filename,extension,directory')
                        .get()
                        .then((oneshotResponse) => {
                            // expects padded image numbers - we have to convert to int otherwise
                            let sortedImages = oneshotResponse.getData().sort(function sortByFilename(a, b) {
                                return parseInt(b.getAttribute('filename')) < parseInt(a.getAttribute('filename')) ?  1
                                    : parseInt(b.getAttribute('filename')) > parseInt(a.getAttribute('filename')) ? -1
                                        : 0;
                            });

                            let searchResult = new SearchResult(latestScan,
                                latestScanRevision,
                                product,
                                latestScan.getMeasurements(),
                                sortedImages[0]
                            );

                            console.log(searchResult);

                            return searchResult;
                        });
                }

                return Promise.all(promises);
            }).then((result) => {
            this.setState({
                isLoaded: true,
                items: result
            });
        }).catch((error) => {
            this.setState({
                isLoaded: true,
                error
            });
        });
    }

    renderTable() {
        return this.state.items.map((item, key) => {
            return {
                img: (
                    <div className="img-container">

                        {item.image && <img src={`${baseURL}cache/` +
                            item.image.getAttribute('directory') + "/" +
                            item.image.getAttribute('filename') + "." +
                            item.image.getAttribute('extension')} alt=""/>}
                    </div>
                ),
                check: (
                    <div>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox"/>
                                <span className="form-check-sign"/>
                            </Label>
                        </FormGroup>
                    </div>
                ),
                id: key,
                uuid: item.product.id,
                article: item.product.getAttribute('skus'),
                length: Math.round(item.measurements.getAttribute('length')) + ' mm', //.toLocaleString("de-DE", {maximumFractionDigits:0}),
                width: Math.round(item.measurements.getAttribute('width')) + ' mm', //.toLocaleString("de-DE", {maximumFractionDigits:0}),
                height: Math.round(item.measurements.getAttribute('height')) + ' mm', //.toLocaleString("de-DE", {maximumFractionDigits:0}),
                weight: Math.round(item.measurements.getAttribute('weight_calc')) + ' g', //.toLocaleString("de-DE", {maximumFractionDigits:0}),
                date: new Date(item.scan.getAttribute('finished')).toLocaleString('de-DE')
            };
        })
    }

    render() {
        const { error, isLoaded /*, items */ } = this.state;
        if (error) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t('Error')}: {error.message}</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else if (!isLoaded) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t('Loading')} {this.props.t('Articles')} ...</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t('Newest')} {this.props.t('Articles')}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable

                                        getTdProps={(state, rowInfo, column /*, instance */) => {
                                            return {
                                                onClick: (e, handleOriginal) => {

                                                    if (column.id !== 'check') {
                                                        this.props.history.push(`/admin/ArticleDetails/${rowInfo.row.uuid}`);
                                                    }

                                                    if (handleOriginal) {
                                                        handleOriginal()
                                                    }
                                                }
                                            }

                                        }}



                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination,
                                          warning-pagination, danger-pagination or none - which will make the pagination
                                          buttons gray
                                        */
                                        className="highlight -striped -highlight primary-pagination"
                                        data={this.renderTable()}
                                        filterable
                                        columns={[

                                            {
                                                Header: "",
                                                accessor: "check",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false,
                                                width: 35
                                            },
                                            {
                                                Header: this.props.t("Img"),
                                                accessor: "img"
                                            },
                                            {
                                                Header: this.props.t("Article"),
                                                accessor: "article"
                                            },
                                            {
                                                Header: this.props.t("Length"),
                                                accessor: "length",

                                            },
                                            {
                                                Header: this.props.t("Width"),
                                                accessor: "width",

                                            },
                                            {
                                                Header: this.props.t("Height"),
                                                accessor: "height",
                                            },
                                            {
                                                Header: this.props.t("Weight"),
                                                accessor: "weight"
                                            },


                                            {
                                                Header: this.props.t("Date"),
                                                accessor: "date",
                                                headerStyle: {textAlign: 'left' },
                                                /* TODO: only works on first click
                                                sortMethod: (a,b,desc) => {

                                                    let ma =  moment(a, 'DD.M.YYYY, hh:mm:ss');
                                                    let mb =  moment(b, 'DD.M.YYYY, hh:mm:ss');

                                                    var cmpResult = (ma > mb) - (ma < mb);
                                                    cmpResult = (desc === true) ? -cmpResult : cmpResult;

                                                    console.log('Comparing ...');
                                                    console.log('Desc is');
                                                    console.log(desc);
                                                    console.log(ma.toISOString());
                                                    console.log(mb.toISOString());
                                                    console.log('Result is');
                                                    console.log(cmpResult);

                                                    return cmpResult;
                                                }, */
                                            },
                                            {
                                                Header: "",
                                                accessor: "uuid",
                                                show: false,
                                            },



                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom
                                        showPaginationTop={false}
                                        minRows={0}
                                        previousText={this.props.t("Previous")}
                                        nextText={this.props.t("Next")}
                                        loadingText={this.props.t('Loading...')}
                                        noDataText={this.props.t('No rows found')}
                                        pageText={this.props.t('Page')}
                                        ofText={this.props.t("of")}
                                        rowsText={this.props.t("rows")}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
}

export default withTranslation()(withRouter(DataTable));
