/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import Dashboard from "views/Dashboard.js";

// import Register from "views/pages/Register.js";
// import Timeline from "views/pages/Timeline.js";
// import User from "views/pages/User.js";
// import Login from "views/pages/Login.js";
// import Rtl from "views/pages/Rtl.js";
// import Lock from "views/pages/Lock.js";

// import Loader from "./components/Loader"

// import SceneComponent from "./components/360Deg/SceneComponent"
// import BoxScene from "./components/360Deg/BoxScene"
// import SceneView3D from "./components/360Deg/SceneView3D"
// import PageWithScene from "./components/3D/PageWithScene"
// import Image360View from "./components/360Deg/Image360View"
// import Image360SceneView from "./components/360Deg/Image360SceneView";
// import UserProfile from "./components/UserProfile";

// import FAQ from "./components/FAQ";
// import Scanner from "./components/Scanner";
// import Choose_scanner from "./components/Choose_scanner";

// import BabyLonBirdViewDemo from "./components/3D/BabyLonBirdViewDemo";
// import ScannerTable from "./components/ScannerTable";

// TODO: make clear that this import is needed for translation
import Page2 from "./components/Multilingual/Page2";

import DataTable from "./components/dataTable";
import ArticleDetails from "./components/articleDetails";

const routes = [

  {
    path: "/dataTable",
    name: "All Articles",
    // rtlName: "هعذاتسجيل الدخول",
    icon: "tim-icons icon-chart-pie-36",
    // rtlMini: "هعذا",
    component: DataTable,
    layout: "/admin"
  },
  {
    path: "/articleDetails/:uuid",
    name: "Selected Article",
    // rtlName: "هعذاتسجيل الدخول",
    icon: "tim-icons icon-zoom-split",
    // rtlMini: "هعذا",
    component: ArticleDetails,
    layout: "/admin"
  },
  /* {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    rtlMini: "شع",
    component: UserProfile,
    layout: "/admin"
  },


  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: "tim-icons icon-image-02",
    state: "pagesCollapse",
    views: [
      {
        path: "/3dview",
        name: "View 3D",
        rtlName: "عالتسعير",
        mini: "P",
        rtlMini: "ع",
        component: BabyLonBirdViewDemo,
        layout: "/admin"
      },
      {
        path: "/360degView",
        name: "Image360View",
        rtlName: "عالتسعير",
        mini: "L",
        rtlMini: "ع",
        component: Image360View,
        layout: "/admin"
      },

      {
        path: "/login",
        name: "Login",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: Register,
        layout: "/auth"
      },*/

      /*
      {
        path: "/scannerTable",
        name: " ScannerTable",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "ST",
        rtlMini: "شع",
        component: ScannerTable,
        layout: "/admin"
      },


      {
        path: "/faq",
        name: "FAQ",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "FAQ",
        rtlMini: "شع",
        component: FAQ,
        layout: "/admin"
      },

      {
        path: "/scanner",
        name: "Choose Scanner",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "Sc",
        rtlMini: "شع",
        component: Choose_scanner,
        layout: "/admin"
      },



    ]
  },*/

];

export default routes;
