import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import AuthLayout from "layouts/Auth/Auth.js";
import apiClient, {baseURL} from './services/apiService';
import { Router, Switch, Route, NavLink } from "react-router-dom";
import Products from '../src/views/product'
import Login from '../src/views/forms/Login'
import { createBrowserHistory } from "history";
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";
import User from '../src/views/forms/User'
import GuardedRoute from './components/GuardedRoute'
import PublicRoute from './components/PublicRoute'

import DataTable from "./components/dataTable";
//import Dashboard from './views/Dashboard'
import Unprotected from './views/Unprotected'

import ArticleDetails from './components/articleDetails'



// reactstrap components

import {useTranslation} from "react-i18next";

const hist = createBrowserHistory();
const App = () => {
    const [loggedIn, setLoggedIn, setUser] = React.useState(
        sessionStorage.getItem('loggedIn') == 'true' || false
    );
    const login = () => {
        setLoggedIn(true);
        sessionStorage.setItem('loggedIn', true);
    };
    const logout = () => {
        apiClient.post(`${baseURL}logout`).then(response => {
            if (response.status === 204) {
                setLoggedIn(false);
                sessionStorage.setItem('loggedIn', false);
                window.location.href="/login";
            }
        })
    };
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const userLog = () => {
        apiClient.get('/user').then(response => {
            if (response.status) {
                let data = response.data;
                console.log("user" + data);
            }
        })
    };
    const authLink = loggedIn
        ? <button onClick={logout} className="nav-link btn btn-link">Logout</button>
        : <NavLink to='/login' className="nav-link">Login</NavLink>;

    if (!loggedIn) {
        return (
            <Router history={hist}>
                <Route exact={true} path="*" render={props => <Login {...props} login={login}/>} />
            </Router>
        );
    } else {
        return (

            <Router history={hist}>
    
                <Switch>
                    {/* User is LoggedIn
-                       <GuardedRoute path='/admin' component={Dashboard} auth={loggedIn} logout={logout} lang={changeLanguage}/>
-                       <GuardedRoute path='/auth' component={Dashboard} auth={loggedIn} logout={logout} lang={changeLanguage}/>
-                       <GuardedRoute path='/admin/dashboard' component={Dashboard} auth={loggedIn} logout={logout} lang={changeLanguage}/>
                    */}

                    <GuardedRoute path='/' component={DataTable} auth={loggedIn} logout={logout} lang={changeLanguage}/>
                    <GuardedRoute path="/admin/ArticleDetails/:uuid" component={ArticleDetails}  />
                        
                    {/*User will LogIn*/}
                    <Route path='/login' render={props => (
                        <Login {...props} login={login} />
                    )} />
                    
                    <Route path='/unprotected' component={Unprotected} />

                    {/*Page Not Found*/}
                </Switch>
    
            </Router>
        );    
    }

};
export default App;
