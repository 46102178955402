import React from "react";
// reactstrap components
import Products from "../product";

import {
    FormGroup,
    Label,
    Input,
    FormText,
    Button,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter
} from "reactstrap";

import axios from 'axios';
import apiClient from '../../services/apiService';
import { Redirect } from 'react-router-dom';

const Login = (props) => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [toHome, setToHome] = React.useState(false);
    const [authError, setAuthError] = React.useState(false);
    const [unknownError, setUnknownError] = React.useState(false);
    const[isAutheticated, setisAutheticated] = React.useState(false);
    const [loading] = React.useState(true);

    //function response(obj){console.log(obj)};

    const handleSubmit = (e) => {
        e.preventDefault();
        setAuthError(false);
        setUnknownError(false);
        apiClient.get('/sanctum/csrf-cookie')
            .then(response => {
                apiClient.post('/login', {
                    email: email,
                    password: password
                }).then(response => {
                    if (response.status === 204) {
                        setisAutheticated(true);
                        console.log("loggedInUser:" + isAutheticated);
                        props.login();
                        console.log("request success");
                        setToHome(true);

                    }
                }).catch(error => {
                    if (error.response && error.response.status === 422) {
                        setAuthError(true);
                    } else {
                        //window.location.href="/auth/dashboard";
                        console.log("Testng");
                        setUnknownError(true);
                        console.error(error);
                    }
                });
            });
    }
    if (toHome === true) {
        console.log("comes to home");
        return <Redirect to='/admin/dataTable' />
    }
    return (
        <div className="container" >
            <Row style={{justifyContent: 'center', marginTop:"15%"}}>
                <Col md="4" sm="12">
                    <Card>
                        <CardHeader>
                        <h3>Login V1</h3>
                        </CardHeader>
                        <CardBody>
                        <form onSubmit={handleSubmit}>

                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    className={"form-control" + (authError || unknownError ? ' is-invalid' : '')}
                                    placeholder="Email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="password"
                                    className={"form-control" + (authError || unknownError ? ' is-invalid' : '')}
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            {authError ? <div className="alert alert-danger">Credentials not recognised. Please try again.</div> : null}
                            {unknownError ? <div className="alert alert-danger">There was an error submitting your details.</div> : null}
                           <CardFooter className="text-right">
                            <button type="submit" className="btn btn-primary">Login</button>
                           </CardFooter>
                        </form>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};
export default Login;
