import React /*, {useState, useRef }*/ from 'react';

import {withTranslation} from "react-i18next";

import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";

import Tridi from 'react-tridi';
import 'react-tridi/dist/index.css';

import {baseURL} from '../../services/apiService'

import {Scan} from "../../models";
import {SortDirection} from "coloquent";

class Image360View extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            tridiRef: null
        }
    }

    componentDidMount() {
            this.updateScan();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.uuid !== prevProps.uuid) {
            this.updateScan();
        }
    }

    updateScan() {
        if (this.props.uuid) {
            Scan.where('id', this.props.uuid)
                .first()
                .then((scanResponse) => {
                    return scanResponse.getData().revisions()
                        .orderBy('position', SortDirection.DESC)
                        .limit(1)
                        .first()
                        .then((revResponse) => {
                            return revResponse.getData().media()
                                .where('tag', 'Kap_360Images')
                                .orderBy('filename', 'asc')
                                .get()
                                .then((oneshotResponse) => {
                                    // expects padded image numbers - we have to convert to int otherwise
                                    let sortedImages = oneshotResponse.getData().sort(function sortByFilename(a, b) {
                                        return parseInt(b.getAttribute('filename')) < parseInt(a.getAttribute('filename')) ?  1
                                                : parseInt(b.getAttribute('filename')) > parseInt(a.getAttribute('filename')) ? -1
                                                : 0;
                                    });

                                    this.setState({
                                        images: sortedImages.map(image => this.buildFileName(image)),
                                        isLoaded: true
                                    });
                                })
                    });

                })
        }
    }

    buildFileName(media) {
        return `${baseURL}cache/` +
            media.getAttribute('directory') + "/" +
            media.getAttribute('filename') + "." +
            media.getAttribute('extension');
    }

    // handler receiving updates when user interacts with the 360° view - uncomment if needed
    /* frameChangeHandler = (currentFrameIndex) => {
        console.log('current frame index', currentFrameIndex);
    }; */

    // uncomment if needed: recording states for click, stop, start
    /* recordStartHandler = (pins) => console.log('on record start', pins);
    recordStopHandler = (pins) => console.log('on record stop', pins);
    pinClickHandler = (pin) => console.log('on pin click', pin); */

    render() {
        const {error, isLoaded} = this.state;
        if (error) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t('Error')}: {error.message}</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else if (!isLoaded) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t('Loading')} 360° {this.props.t('Images')} ...</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            //rendering images and actions onclick
            return (
                <div style={{width: '100%'}}>
                    <Tridi
                        ref={this.state.tridiRef}
                        //images from public folder
                        images={this.state.images}
                        // location="/images"
                        // format="jpg"
                        // count="16"
                        onFrameChange={this.frameChangeHandler}
                        autoplaySpeed={70}
                        onAutoplayStart={() => this.setState({isAutoPlayRunning: true})}
                        onAutoplayStop={() => this.setState({isAutoPlayRunning: false})}
                        // onRecordStart={this.recordStartHandler}
                        // onRecordStop={this.recordStopHandler}
                        // onPinClick={this.pinClickHandler}
                        // inverse
                        showControlBar
                    />

                </div>
            );
        }
    }
}

export default withTranslation()(Image360View);
