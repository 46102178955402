import React, { useState } from "react";
import axios from 'axios';
import {Model} from "coloquent";
import {AxiosHttpClient} from "coloquent/dist/httpclient/axios/AxiosHttpClient";
// import {
//     NOT_LOGGED_IN,
//     LOG_IN_FORM,
//     SIGN_UP_FORM,
//     LOGGED_IN
//
// } from "../constants/AuthStatus";

let port = "8000"
//let apiURL = `http://api.demo.kaptura-access.test:${port}/api/v1/`;
//let baseURL = `http://api.demo.kaptura-access.test:${port}/`;
let apiURL = `https:///api.demo.access.kaptura.de/api/v1/`;
let baseURL = `https:///api.demo.access.kaptura.de/`;

const apiClient = axios.create({
    //change below URL if server
  baseURL: 'https://api.demo.access.kaptura.de',
//    baseURL: `http://api.demo.kaptura-access.test:${port}/`,
    withCredentials: true,
});

// TODO: quick fix, implement second solution on
//       https://blog.anasmazouni.dev/solving-laravel-vue-spa-419-token-mismatch/
apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error)
        /* if (error.response.status !== 419) return Promise.reject(error)
        window.location.reload() */
    }
)

let axiosHttpClient = new AxiosHttpClient(apiClient);
axiosHttpClient.setWithCredentials(true);
Model.setHttpClient(axiosHttpClient);

export default apiClient;
export {apiURL, baseURL};
