import React from 'react';
import { Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
const Unprotected = () => {
    return (
        <div>
            <h1>Landing</h1>
            <Col md={{ size: 6, offset: 3 }} className="unauthenticated-container">
                <h5>Login to view more products</h5>
                <p>
                    See all pictures, 3D Scans, subscribe to kaptura accesss to get a catered list of upcoming features
                    that fits your interests!
                </p>
                <Button
                    className="has-icon"
                    color="success"
                    outline
                    tag={Link}
                    to="/login"
                >
                    <span>Login</span>
                </Button>
            </Col>
        </div>
    )
};

export default Unprotected;
