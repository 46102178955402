import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardDeck,
    // CardGroup,
    // CardColumns,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    // CardText,
    // CardImg,
    // FormGroup,
    // Label,
    // Input,
    // FormText,
    // Table,

} from "reactstrap";
import Image360View from "./360Deg/Image360View"
import Dimensions from "./Dimensions";
import "./ComponentsCustomStyle.css"
import { withRouter } from "react-router";
import ScanInfo from "./ScanInfo";
import BabyLon3DViewer from "./3D/BabyLon3DViewer";
import {withTranslation} from "react-i18next";


class ArticleDetails extends React.Component {
    constructor( props ) {
        super(props);

        this.state = { uuid: this.props.match.params.uuid }
    }

    componentDidMount() {
        console.log(this.props.match.params.uuid)
    }

    setScan(uuid) {
        this.setState({ scan: uuid });
    }

    render() {
        if (this.state.uuid === ':uuid') {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">
                                        {this.props.t('Please select an article from the overview first. The details will be shown here.')}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (
                <div className="content">
                    <CardDeck>
                        <Card style={{ marginBottom: 25}}>
                            <CardHeader>
                                <h3>{this.props.t('360-view')}</h3>
                            </CardHeader>
                            <CardBody>
                                <Image360View uuid={this.state.scan} />
                                {/* <div className="float-right">
                                        <p>{this.props.t('Download-product-pictures')}
                                            <Button color="primary"
                                                    className=" btn-icon btn-link "> <i className="fa fa-download" /> </Button>
                                        </p>
                                    </div> */}
                            </CardBody>
                        </Card>
                        <Card style={{ marginBottom: 25}}>
                            <CardHeader>
                                <h3>{this.props.t('3D-view')}</h3>
                            </CardHeader>
                            <CardBody>
                                <BabyLon3DViewer uuid={this.state.scan} key={this.state.scan} />
                            </CardBody>
                        </Card>
                    </CardDeck>

                    <CardDeck>
                        <Card>
                            <CardHeader>
                                <h3>{this.props.t('All-scans')}</h3>
                            </CardHeader>
                            <CardBody>
                                <ScanInfo uuid={this.state.uuid} onClick={(uuid) => this.setScan(uuid)} />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h3>{this.props.t('Dimensions')}</h3>
                            </CardHeader>
                            <CardBody>
                                <Dimensions uuid={this.state.scan} />
                            </CardBody>
                        </Card>
                    </CardDeck>
                </div>
            );
        }
    }
}

export default withTranslation()(withRouter(ArticleDetails));