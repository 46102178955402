import React from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    // FormGroup,
    // Input,
    // Label,
    // Table
} from "reactstrap";

import {withTranslation} from "react-i18next";
import {Product} from 'models';
import apiClient from "../services/apiService";

class ScanInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
    }

    handleUpload(uuid, destination = 'demoshop_uploads', action = 'upload') {
        let url = `/actions/scans/${action}/${uuid}/to/${destination}`;

        apiClient.post(url)
            .then((response) => {
                console.log(`${action} called with response:`);
                console.log(response);
            })
    }

    componentDidMount() {
        this.updateProduct();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.uuid !== prevProps.uuid) {
            this.updateProduct();
        }
    }

    updateProduct() {
        Product.where('id', this.props.uuid).with('scans').first()
            .then((response) => {
                    console.log(response)

                    this.setState({
                        isLoaded: true,
                        items: response.data.getScans()
                    });

                    if (response.data.getScans()) {
                        let firstScan = response.data.getScans()[0];

                        this.props.onClick(firstScan.id);
                        this.setState({selected: 0})
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    renderTable() {
        return this.state.items.map((item, key) => {
            return {
                id: key,
                uuid: item.id,
                date: item.getAttribute('finished'),
                time: item.getAttribute('finished'),
                mode: item.getAttribute('mode'),
                preset: item.getAttribute('preset'),
                uploadToDemoShop: (<Button className={'uploadBtn'}
                                           onClick={(e) => this.handleUpload(item.id, 'demoshop_uploads', 'export')}>Shop<i
                    className="tim-icons icon-upload"
                    style={{verticalAlign: "baseline", top: "0px", marginLeft: "3px"}}/></Button>),
                uploadToFTP: (<Button className={'uploadBtn'}
                                      onClick={(e) => this.handleUpload(item.id, 'sftp_k4r', 'upload')}>FTP<i
                    className="tim-icons icon-upload"
                    style={{verticalAlign: "baseline", top: "0px", marginLeft: "3px"}}/></Button>)
            };
        })
    }

    render() {
        const {error, isLoaded /*, items */} = this.state;
        if (error) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t('Error')}: {error.message}</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else if (!isLoaded) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle
                                        tag="h4">{this.props.t('Loading')} {this.props.t('Scans')} ...</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            console.log("Render is called with uuid: " + this.props.uuid);
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <ReactTable

                                getTdProps={(state, rowInfo/* , column, instance */) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            this.setState({
                                                selected: rowInfo.index
                                            });

                                            // uncomment for debugging selection - console.log('Selected scan is:', rowInfo.row.uuid)
                                            // TODO: switch uuid of current scan
                                            //       has to be a property of the parent (articleDetails)
                                            this.props.onClick(rowInfo.row.uuid);

                                            if (handleOriginal) {
                                                handleOriginal()
                                            }
                                        },
                                        style: {
                                            background: rowInfo.index === this.state.selected ? '#525f7f' : '#27293d',
                                            /* textAlign: 'right' */
                                            // uncomment if needed - color: rowInfo.index === this.state.selected ? '' : ''
                                        }
                                    }
                                }}


                                /*
                                  You can choose between primary-pagination, info-pagination, success-pagination,
                                  warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                */
                                className="highlight -striped -highlight primary-pagination"
                                data={this.renderTable()}
                                columns={[
                                    {
                                        Header: this.props.t("Date"),
                                        accessor: "date",
                                        width: "auto"
                                    },
                                    {
                                        Header: this.props.t("Time"),
                                        accessor: "time",
                                        width: "auto"

                                    },
                                    {
                                        Header: this.props.t("Mode"),
                                        accessor: "mode",
                                        width: "auto"
                                    },
                                    {
                                        Header: this.props.t("Preset"),
                                        accessor: "preset",
                                        width: "auto"
                                    },
                                    {
                                        Header: "Upload",
                                        accessor: "uploadToFTP",
                                        width: "auto"
                                    },
                                    {
                                        Header: "Upload",
                                        accessor: "uploadToDemoShop",
                                        width: "auto",
                                        headerStyle: {textAlign: 'left'},
                                    },
                                    {
                                        Header: "",
                                        accessor: "uuid",
                                        show: false
                                    },
                                ]}
                                // defaultPageSize={10}
                                showPaginationBottom={false}
                                showPaginationTop={false}
                                minRows={0}
                                // previousText={this.props.t("Previous")}
                                // nextText={this.props.t("Next")}
                                loadingText={this.props.t('Loading...')}
                                noDataText={this.props.t('No rows found')}
                                pageText={this.props.t('Page')}
                                ofText={this.props.t("of")}
                                rowsText={this.props.t("rows")}
                            />
                        </Col>
                    </Row>
                </div>
            )
        }
    }

}

export default withTranslation()(ScanInfo);