import {Model, PaginationStrategy} from 'coloquent';
import {apiURL} from '../src/services/apiService'

class KapModel extends Model {

    getJsonApiBaseUrl()
    {
        return apiURL;
    }

}

class Media extends KapModel
{

    jsonApiType = 'media';

    /* 
     * Set relation name explicitly using the second parameter
     * of hasMany.
     *
     * Otherwise the uri for querying the sub relation will
     * be wrong - the "hacky" resolution isn't working when
     * creating a build for deployment.
     * 
     * see
     * https://github.com/DavidDuwaer/Coloquent/issues/33
     */
    revisions()
    {
        return this.hasMany(ScanRevision, 'revisions')
    }

    getRevisions()
    {
        return this.getRelation('revisions')
    }

    static pageSize = 120;
    static paginationStrategy = PaginationStrategy.PageBased;

}

class ScanRevision extends KapModel
{

    jsonApiType = 'revisions';

    /* second parameter is needed, see documentation above */
    media()
    {
        return this.hasMany(Media, 'media');
    }

    getMedia()
    {
        return this.getRelation('media')
    }

    /* second parameter is needed, see documentation above */
    user()
    {
        return this.hasOne(User, 'users');
    }

    getUser()
    {
        return this.getRelation('user')
    }

    /* second parameter is needed, see documentation above */
    scan()
    {
        return this.hasOne(Scan, 'scans');
    }

    getScan()
    {
        return this.getRelation('scan')
    }

    pageSize = 30;
}

class Measurements extends KapModel
{

    jsonApiType = 'measurements';

    pageSize = 30;
}

class Scan extends KapModel
{

    jsonApiType = 'scans';

    /* second parameter is needed, see documentation above */
    product()
    {
        return this.hasOne(Product, 'products');
    }

    getProduct()
    {
        return this.getRelation('product');
    }

    /* second parameter is needed, see documentation above */
    revisions()
    {
        return this.hasMany(ScanRevision, 'revisions');
    }

    getRevisions()
    {
        return this.getRelation('revisions');
    }

    /* second parameter is needed, see documentation above */
    measurements()
    {
        return this.hasOne(Measurements, 'measurements');
    }

    getMeasurements()
    {
        return this.getRelation('measurements');
    }

    pageSize = 30;
}

class Product extends KapModel
{

    jsonApiType = 'products';

    /* second parameter is needed, see documentation above */
    scans()
    {
        return this.hasMany(Scan, 'scans');
    }

    getScans()
    {
        return this.getRelation('scans');
    }

    static pageSize = 20;
    static paginationStrategy = PaginationStrategy.PageBased;
}

class User extends KapModel
{

    jsonApiType = 'user';

    pageSize = 30;
}

export {Scan, ScanRevision, Media, Measurements, Product, User};
